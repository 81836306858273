import {Formik, Form, Field, ErrorMessage} from 'formik';
import { useState } from 'react';
import TextInput from './TextInput';
import Select from './Select';
import TextArea from './TextArea';
import MenuItem from '@mui/material/MenuItem';
import generos from './generos';
import paises from './paises';
import redes from './redes';
import emailjs from 'emailjs-com';
import SuccessModal from '../successModal';
import '../styles/sectionFormulario.css';

const FormRegister = ({tipoForm}) => {
  const [sex, setSex] = useState("");
  const [pais, setPais] = useState("");
  const [source, setSource] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangeSex = (event) => {
    setSex(event.target.value);
  };

  const handleChangePais = (event) => {
    setPais(event.target.value);
  };

  const handleChangeSource = (event) => {
    setSource(event.target.value);
  };

  const validate = values => {
    values.sex = document.getElementsByName("sex")[0].value;
    values.pais = document.getElementsByName("pais")[0].value;
    values.source = document.getElementsByName("source")[0].value;

    let errors = {};
    if (!values.name){ 
      errors.name = 'Requerido'; 
    }else if (values.name.length < 3){errors.name = 'El nombre debe tener al menos 3 caracteres';}
    if (!values.mail) errors.mail = 'Requerido';
    if (!values.mail.includes("@")) errors.mail = 'Email inválido';
    if (!values.sex) errors.sex = 'Requerido';  
    if (!values.pais) errors.pais = 'Requerido';     

    return errors;
  }    

  const handleSubmit = (values, { setSubmitting }) => {
    setIsSubmitting(true);
    setShowModal(true);
    
    const templateParams = {
      name: values.name,
      lastname: values.lastname,
      email: values.mail,
      sex: values.sex,
      pais: values.pais,
      source: values.source,
      comment: values.comment,
      tipoForm: values.tipoForm
    };

    emailjs.send('service_f02ye8e', 'template_e2ax1la', templateParams, 'XzVM1KQUEazpLdYs6')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setShowModal(true);        
        setIsSubmitting(false);
        setSubmitting(true);
      }, (error) => {
        console.log('FAILED...', error);
        alert('Error al enviar el correo');
        setIsSubmitting(false);
        setSubmitting(false);
      });
  };

  return (    
    <>
        <SuccessModal showModal={showModal} setShowModal={setShowModal} sending={isSubmitting}/>  
        <Formik 
        initialValues={{name: '', lastname: '', mail: '', sex: "", pais:'', source:'', accept: false, comment: '', tipoForm: tipoForm}}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <Form >
          <div id="contact-form" >
            <div className='contact-div'><TextInput label="Nombre" id="contact-name" name="name" type="text" /></div>
            <div className='contact-div'><TextInput label="Apellido" id="contact-lastname" name="lastname" type="text" /></div>
            <div className='contact-div'><TextInput label="Email" id="contact-mail" name="mail" type="mail" /></div>
            <div className='contact-div'><Select select id="contact-textArea" label="Género" className="contact-select" value={sex} onChange={handleChangeSex} name="sex">
              {generos.map((option) => (
                <MenuItem  key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select> </div>
            <div className='contact-div'> <Select select label="Pais" id="contact-nation" className="contact-select" name="pais" value={pais} onChange={handleChangePais} >
              {paises.map((option) => (
                  <MenuItem  key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
              ))}
            </Select>  </div>
            <div className='contact-div'><Select select label="De dónde nos conoces?" id="contact-source" className="contact-select" name="source" value={source} onChange={handleChangeSource}>
            {redes.map((option) => (
                  <MenuItem  key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
              ))}              
            </Select> </div>           
            <div className='contact-div' id="contact-comment"><TextArea  label="Temas a tratar" multiline rows={4}  name="comment" type="text" /> </div>
          </div>            
          <button id="contact-submit" type="submit">Enviar</button>
        </Form>
      </Formik>   
    </>
  );
}

export default FormRegister;