import Youtube from "react-player/youtube";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from 'react-slick';
import '../../styles/showbar.css';
import { memo } from 'react';

const ShowbarVideos = (props) => {
    const settings = {
        dots: true, // Puedes activar puntos de navegación
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Número de videos a mostrar al mismo tiempo
        slidesToScroll: 1,
        autoplay: true, // Para que el carrusel se deslice automáticamente
        autoplaySpeed: 3000,
        arrows: false, // Flechas de navegación
        responsive: [
            {
                breakpoint: 1520, // Ancho máximo de pantalla (desktop)
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1100, // Ancho máximo de pantalla (tablet)
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480, // Ancho máximo de pantalla (móvil)
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
      };

    return (
        <div id="contenedorVideos" >
                        <Slider {...settings}>
                        {props.videos.map((video) => (
                            <div key={video.url} className="videos" >
                            <Youtube url={video.url} width="350px" height="200px" controls />
                            </div>
                        ))}
                        </Slider>
                    </div>
    );
}

export default memo(ShowbarVideos);