import React from 'react'
import { NavLink } from 'react-router-dom'
import '../styles/footer.css'
import "../../App.css";

const redes = [
    { id: 1, class: 'fab fa-youtube redes youtube', link: 'https://www.youtube.com/@aprendemosdetodo'},
    { id: 2, class: 'fab fa-tiktok redes tiktok', link: 'https://www.tiktok.com/@aprendemosdetodo'},
    { id: 3, class: 'fab fa-instagram redes instagram', link: 'https://www.instagram.com/aprendemosdetodo/'},
    { id: 4, class: 'fab fa-linkedin redes linkedin', link: 'https://www.linkedin.com/in/paola-albareti-a0847a234/'},   
    { id: 5, class: 'fab fa-facebook redes facebook', link: 'https://www.facebook.com/aprendemosdetodo'}
]


const Contenedor = () => {
    return (
        <div id="divContenedorFooter">
            <div id="divTituloFooter">
                <p  id="tituloFooter">© 2024 - Aprendemos De TODO. All rights reserved</p>
            </div>
            {/* <a id="flechaFooter" href="#inicio" class="arriba">
                <i class="fa-solid fa-angles-up"></i>
            </a> */}
            <div id="divRedes">
                {redes.map((item) => (
                  <div className='circuloLink'>
                    <NavLink key={item.id} to={item.link} target="_blank"><i className={item.class} ></i></NavLink>
                  </div>
              ))}
            </div>     
            <div style={{width:"5rem"}} />
        </div>
    )
}

const footer = () => {
  return (
    <footer id='footer'>
      <Contenedor />
    </footer>
  )
}

export default footer
