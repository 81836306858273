import { useField } from "formik";
import TextField from '@mui/material/TextField';
import '../styles/sectionFormulario.css'

const TextInput = ({ ...props }) => {
    const [field, meta] = useField(props);

    // console.log('props', meta);
    return (
        <div>
            {/* <label className="label">{label}</label>
            <input className="input"{...field} {...props} /> */}            
            {meta.touched && meta.error ? (
                <div className="error" error>
                    <TextField error className="input" {...field} {...props}/>
                    <div>{meta.error}</div>
                </div>
            ) : <TextField className="input" {...field} {...props}/>}
        </div>
    );
}

export default TextInput;