import React from 'react'
import '../../styles/section6.css'
import LinkedInProfile from './linkedin'
import perfil from './perfil.png'

const Contenedor = () => {
    const exampleProfile = {
      picture: 'https://example.com/profile-pic.jpg',
      name: 'John Doe',
      title: 'Software Engineer',
      location: 'San Francisco, CA',
      summary: 'Experienced software engineer with a passion for developing innovative programs. Skilled in various programming languages and frameworks.',
      skills: ['JavaScript', 'React', 'Node.js', 'Python']
  };
  return (
      <div id="divContenedorSection6">
          <div id='contenedorSection6'>            
          </div>
          <div id="descriptionSection6">
              <p className="tituloh1">¿Quién es AprendemosDeTODO?</p>
              <p className="texto">¡Hola! ¿Cómo estás? Soy Paola, una docente como vos. Me especializo en el desarrollo de personas que desean enseñar. ¡Encantada de conocerte!</p>

          </div>
          <div>
            <p className='tituloh1'>Lic. PAOLA ALBARETI</p>
            <p className='tituloh2'>Especialista en Didáctica</p>
          </div>
          <a href="https://www.linkedin.com/in/paola-albareti-a0847a234/" target="_blank" rel="noopener noreferrer">
            <img id="perfilLinkedin" src={perfil} alt="Paola Albareti's profile" />
          </a>
      </div>
  )
}

const Section6 = () => {      
    return (
      <section id="section6" className='section'> 
          <div id="divMenuSection6">
          <Contenedor />
          </div>
      </section>
    )
  }
  
  export default Section6