import React from 'react'
import '../../styles/section4.css';
import '../../../App.css';
import Accordions from './accordion';

const questionsAnswers = [
    {
      question: "Didáctica General",
      answer:
        "Se estudian los diferentes modelos y teorías pedagógicas, como la Tradicional, Conductista, Constructivista, Sociocognitiva, etc. Dentro del contexto educativo, las características del aula y la escuela. El currículo escolar, las políticas educativas y los factores socioculturales que influyen en la educación.",
      autores: "Juan Amos Comenio, Daniel Felman, Alicia Camilloni, José Domingo Contreras, entre otros.",
      },
      {
        question: "Sujeto del aprendizaje",
        answer: "Analizar y comprender al sujeto que aprende desde diferentes perspectivas teóricas y prácticas, abordando sus características individuales e influencias sociales y culturales que afectan su proceso.",
        detail: " Por lo que se estudian las Teorías del Aprendizaje como el conductismo, cognitivismo, constructivismo, humanismo, socio-cultural y su aplicación de estas teorías en contextos educativos.",
        autores: "Jean Piaget, Lev Vygotsky, Jerome Bruner, Robert Gagné, David Ausubel, John B. Watson, entre otros.",
      },
      {
        question: "Didáctica de la Matemática",
        answer: "Se enfoca en el estudio de los métodos y estrategias para enseñar matemáticas de manera efectiva en diferentes niveles educativos.",
        detail: " Su objetivo principal es proporcionar a los futuros docentes las herramientas, conocimientos y habilidades necesarios para enseñar matemáticas considerando tanto el contenido matemático como los procesos de aprendizaje de los estudiantes.",
        autores: "Guy Brousseau, Gerard Vergnaud, Michele Artigue, Yves Chevallard, Hans Freudenthal, entre otros.",
      },
      {
        question: "Psicología Educacional",
        answer: "Estudia cómo las personas aprenden y desarrollan conocimientos y habilidades en entornos educativos.",
        detail: " Se centra en comprender los procesos psicológicos involucrados en el aprendizaje y la enseñanza, así como en aplicar este conocimiento para mejorar la práctica educativa y el rendimiento académico.",
        autores: "Jean Piaget, Lev Vygotsky, Jerome Bruner, Robert Gagné, David Ausubel, John B. Watson, entre otros.",
    },
    {
      question: "Pedagogía",
      answer: "Disciplina fundamental dentro del ámbito educativo que se enfoca en el estudio de los principios, métodos, técnicas y estrategias que optimizan el proceso de aprendizaje..",
      detail: " Su objetivo principal es facilitar la adquisición de conocimientos, habilidades y valores en los estudiantes de manera efectiva y significativa..",
      autores: "Juan Amós Comenio, Jean-Jacques Rousseau, Johann Heinrich Pestalozzi, John Dewey, María Montessori, entre otros.",
    }    
  ];

const Contenedor = () => {
    return (
        <div id="divContenedorSection4">
            <div id='contenedorSection4'>  
                <Accordions questionsAnswers={questionsAnswers} />              
            </div>
            <div id="descriptionSection4">
                <p className='tituloh1'>¿Qué materias cubrimos?</p>
                <p className="texto">Aquellas enfocadas en enseñar a enseñar</p>
            </div>
        </div>
    )
}

const section4 = () => {
  return (
    <section id="section4" className='section'> 
        <div id="divMenuSection4">
            <Contenedor />
        </div>
    </section>
  )
}

export default section4