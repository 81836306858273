import React from 'react'
import "../styles/section0.css"
import { NavLink } from 'react-router-dom'
import "../styles/footer.css"
import { BookOpenIcon } from '@heroicons/react/24/solid'
// import redes from "../assets/home/section0/z.png"

const redirectToYoutube = () => {
    window.location.href = 'https://www.youtube.com/@aprendemosdetodo';
};

const ContenedorImagen = () => {
    return (        
        <div id="divImgSection">
            <div id="containerYoutubeLink" className='circuloLink0'>
                    <NavLink to='https://www.youtube.com/@aprendemosdetodo' target="_blank"><i  className='fab fa-youtube redes0 youtube0 '></i></NavLink>
            </div>
            <div id="containerImg">
                <div id="imgPortada" onClick={redirectToYoutube} title="Aprendemos de Todo - Youtube"/>
            </div>
        </div>
    )
}

const ContenedorTitulo = () => {
    return (
        <div id="divTituloSection0">
            <div id="containerTitulo">
                <p id="tituloSection0">APRENDE A <span style={{color:"#18997bcb"}}>TRANSMITIR</span> TU CONOCIMIENTO</p>
                <p id="subtituloSection0">Explora estrategias y técnicas para mejorar la educación y marcar la diferencia en la vida de tus estudiantes</p>
                <p id="subtituloChicoSection0">
                    Desde 2019 <BookOpenIcon style={{ width: '15px', height: '15px', color:"#26130C"}} />
                </p>
            </div>
        </div>
    )
}

const section0 = () => {
  return (
    <section id="section0" className='section'> 
        <ContenedorTitulo />
        <ContenedorImagen />
        {/* <div id="section0Img">  
            <div id ="img1" className='imageContainer'/>
            <div id ="img2" className='imageContainer'/>
            <div id ="img3" className='imageContainer'/>
        </div> */}
    </section>
  )
}

export default section0
