import React from 'react'
import { useState } from 'react';
import '../styles/navBar.css';
import '@fortawesome/fontawesome-free/css/all.css';
import NavMenu from './navMenu';

const MenuLateral = ({ scrollUp, mediaMin }) => {
    const props = { scrollUp, mediaMin };
    const [menuOpen, setMenuOpen] = useState(false);
    const handleClick = () => {
      setMenuOpen(!menuOpen);
    }
    console.log('menuOpen: ', menuOpen)
  
    return (
      <div id="navMenuLateral" className={(scrollUp || !mediaMin) ? 'scrolled' : ''}>
            <div id="menuLateral" onClick={handleClick} className={menuOpen ? "abierto" : ""}>
              {!menuOpen ? <i className="fas fa-bars"></i> : <i className="fa-solid fa-xmark"></i>}
            </div>
            {menuOpen ?  <NavMenu {...props} setMenuOpen={setMenuOpen} /> : <span/>}
      </div>
    )
  }
export default MenuLateral;
