import React, { useEffect, useState } from 'react';
import '../../styles/section4.css';

const AccordionItem = ({
    showDescription,
    ariaExpanded,
    fontWeightBold,
    item,
    index,
    onClick,
  }) => (
    <div className="faq__question" key={item.question} style={{opacity: showDescription? 1 : 0.7, transform: showDescription ? 'scale(1.1)' : 'scale(1)',
      transition: 'transform 0.3s ease, opacity 0.3s ease',}}>
      <dt>
        <button
          aria-expanded={ariaExpanded}
          aria-controls={`faq${index + 1}_desc`}
          data-qa="faq__question-button1"
          className={`faq__question-button1 ${fontWeightBold}`}
          onClick={onClick}
          >
          
          <p style={{color: ariaExpanded ? "#18171C" : "black"}}> {item.question}</p>
        <p className='parrafosAcordeon' style={{fontSize:"14px", color:"grey", marginRight: ariaExpanded ? "50px" : "0px"}}>{item.autores}</p>

        </button>
      </dt>
      <dd>
        <p
          id={`faq${index + 1}_desc`}
          data-qa="faq__desc"
          className={`faq__desc ${showDescription}`}
          style={{height:"auto", maxHeight: showDescription ? '200px' : '0', textAlign:"justify"}}
        >
          {item.answer}
          {item.detail}
        </p>
      </dd>
    </div>
  );

const Accordions = ({ questionsAnswers }) => {
    const [activeIndex, setActiveIndex] = useState(1);
  
    const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
      const showDescription = index === activeIndex ? "show-description" : "";
      const fontWeightBold = index === activeIndex ? "font-weight-bold" : "";
      const ariaExpanded = index === activeIndex ? "true" : "false";
      return (
        <AccordionItem
          showDescription={showDescription}
          fontWeightBold={fontWeightBold}
          ariaExpanded={ariaExpanded}
          item={item}
          index={index}
          onClick={() => {
            setActiveIndex(index === activeIndex ? null : index);
          }}
        />
      );
    });
  
    return (
      <div className="faq" style={{width:"100%"}}>
        {/* <h1 className="faq__title">FAQ</h1> */}
        <dl className="faq__list">{renderedQuestionsAnswers}</dl>
      </div>
    );
  };

export default Accordions;