import NavBar from "../components/navBar/navBar";
import Section0 from "../components/home/section0";
import Section1 from "../components/home/section1/section1";
import Section4 from "../components/home/section4/section4";
import Section6 from "../components/home/section6/section6";
import Footer from "../components/footer/footer";
import '../App.css';

const Home = () => {
    return (
        <div className="contenedorIndex" >
            <NavBar />

            <section id="sectionHeader" className="section" style={{height:55}}/>
            <Section0 />
            <Section1 />
            <Section6 />
            <Section4 />

            <Footer />
        </div>
    );
};

export default Home;
