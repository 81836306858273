import "../../styles/section1.css";
import Carrusel2 from "./carruselCard";
import img1  from "../../assets/home/section1/1.webp";
import img2 from "../../assets/home/section1/4.webp";
import img3 from "../../assets/home/section1/3.webp";
import img4 from "../../assets/home/section1/5.webp";

const cards = [  
    {
      id: 1,
      codigo: "cardClase",
      img: img1,
      title: "CLASES DE APOYO",
      subTitle: "Empezá a aprender entre cientos de materias!",
      text:
        "Descubre las mejores clases personalizadas, totalmente online, donde encontrarás todo lo que se necesita: pizarra digital, edición de documentos, youtube, ¡y mucho más!",
      comment: "Pss! Reservá una clase de 10min gratis para que veas como funciona.",
      url:"/servicios/clases/"                                                                                                                                                                         
    },
    {
      id: 2,
      img: img2,
      codigo: "cardTrabajo",
      title: "AYUDA CON ENTREGAS",
      subTitle: "Nosotros te cubrimos siempre",
      text:
        "Contamos con una amplia gama de recursos académicos, desde tesis y revisiones hasta apoyo en el desarrollo, correcciones, trabajos prácticos, entregas y más, garantizando siempre el aprendizaje.",
      comment: "Y si, por supuesto que si, intentamos que apruebes con la mejor nota!",
      url:"/servicios/entregas/"
    },
    {
      id: 3,
      img:img3,
      codigo: "cardCursos",
      title: "CURSOS ONLINE",
      subTitle: "Aprende a tu ritmo",
      text:"Nuestros cursos online están diseñados de forma didáctia con una variedad de temas disponibles, podrás acceder a materiales didácticos, videos y proximamente evaluaciones que se ajustan a tu nivel de conocimiento.",
      comment: "Estudia como quieras, donde quieras!",
      url:"/servicios/cursos/"
    },
    {
      id: 4,
      img: img4,
      codigo: "cardResumenes",
      title: "RESÚMENES",
      subTitle: "Apuntes didácticos de calidad!",
      text:"Nuestros resumenes son elaborados por una experta en la materia didáctica, mapas conceptuales, diagramas, tablas de contenidos, con el objetivo de facilitar el estudio y la comprensión de los temas más complejos.",
      comment: "Hechos con dedicación, para que puedas estudiar de forma más eficiente!!",
      url:"/servicios/resumenes/"
    }
  ];

const Section1 = () => {
    return (
        <section id="section1" className="section" >
            <div id="descriptionSection1">
                <p className="tituloh1">¿Qué ofrecemos?</p>
                <p className="texto">Herramientas para el éxito en tus estudios</p>
            </div>
            <Carrusel2 cards={cards} autoPlay={true}/>
        </section> 
    );
}

export default Section1;