const Contenedor = (props) => {
    return (
        <div id="divContenedorSection8">
            <p id='tituloProximamente'>{props.h1}</p>
            <div id='contenedorSection8'>
                <div id="divTextSection8">
                    <p className='tituloh2' style={{fontStyle:"italic"}}>{props.h2}</p>
                    <p className="texto">{props.texto}</p>
                </div>
            </div>
        </div>
    )
}

const Proximamente = (props) => {
    return(
        <section id="section8" className='section'> 
        <div id="divMenuSection8">
            <Contenedor {...props}/>
        </div>
    </section>
    )
}

export default Proximamente;
