import '../../../components/styles/section9.css';
import ShowbarVideos from "./showbarVideos";

const videos = [
    { url: "https://www.youtube.com/watch?v=Xv_rHK-GqVg"},
    { url: "https://www.youtube.com/watch?v=Pl0cYtasSAA"},
    { url: "https://www.youtube.com/watch?v=u9Q2tZT3tPA"},
    { url: "https://www.youtube.com/watch?v=XHCSn3e3W7M"},
    { url: "https://www.youtube.com/watch?v=XCJDT-GVgFk"},
    { url: "https://www.youtube.com/watch?v=HcIjHHmJWkU"}
    ];

const Section9 = () => {   
    return (
        <section id="section9" className="section">   
                <div id="divMenuSection9">
                    <p id='tituloVideos'>Mientras tanto...</p>
                    <div id='contenedorSection9'>
                        <div id="divTextSection9">
                            <p className='tituloh2' style={{fontStyle:"italic"}}>Mira nuestros videos!</p>
                            <p className="texto">Estamos desarrollando contenido para toda la comunidad de Aprendemos de TODO, seguinos en las redes y youtube para no perderte de nada!</p>
                        </div>
                    </div>
                    
                    <ShowbarVideos videos={videos}/>
                </div>
                
        </section>
    );
  };

export default Section9;
