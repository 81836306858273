import React from 'react'
import '../../App.css';
import SectionFormulario from "../../components/formRegister/sectionFormulario/sectionFormulario";
import Section2 from "../../components/informacion/section2";
import Section3 from "../../components/informacion/section3";
import NavBar from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";

const Entregas = () => {
  return (
    <div className="contenedorIndex">
      <NavBar /> 
      <section id="sectionHeader" className="section" style={{height:55}}/>
      <Section2 
        h1={"¿Cómo se realiza el práctico?"}
        h2={"Hacemos clases para vos, estudiamos y lo resolvemos juntos!"} 
        texto={"Las entregas se realizan en formato digital, a través del mail de la institución. En caso de ser necesario, se pueden realizar en formato físico, con costo de envío a cargo del cliente."}/>
      <Section3
        h1={"¿Cuándo?"}
        h2={"Cuando tú lo necesites!"}
        texto={"Nos adaptamos a tus tiempos y necesidades. Si tenés una fecha límite, no dudes en comunicárnoslo para que podamos cumplir con tus expectativas."}
        />
      <SectionFormulario titulo={"¿Lo resolvemos juntos?"}/>
      <Footer />
    </div>
  )
}

export default Entregas