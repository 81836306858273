import { useEffect, useState } from "react";
import "../../styles/section1.css";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import React from "react";
import { NavLink } from 'react-router-dom';

function Carousel(props) {
  const { cards = [], autoPlay = false, showButtons = true } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);

  useEffect(() => {
    let interval;
    if (autoPlay && cards.length > 0) {
      interval = setInterval(() => {
        selectNewItem(cards);
      }, 7000);
    }
    return () => clearInterval(interval);
  }, [autoPlay, cards, selectedIndex]);

  useEffect(() => {
    const images = cards.map(card => {
      const img = new Image();
      img.src = card.img;
      return img;
    });
    setPreloadedImages(images);
  }, [cards]);

  const selectNewItem = (items, next = true) => {
    if (!items.length) return; // Avoid error if items array is empty
    const condition = next ? selectedIndex < items.length - 1 : selectedIndex > 0;
    const nextIndex = next ? (condition ? selectedIndex + 1 : 0) : condition ? selectedIndex - 1 : items.length - 1;
    setSelectedIndex(nextIndex);
  };

  const previous = () => {
    selectNewItem(cards, false);
  };

  const next = () => {
    selectNewItem(cards);
  };

  const handleClick = () => {
    window.location.href = currentCard.url;
  };

  const currentCard = cards.length ? cards[selectedIndex] : {};

  return (
    <div className="carousel">
      <div className="divArrow">
        {showButtons ? (
          <button className="leftArrow arrow" onClick={previous}>
            <IoIosArrowBack />
          </button>
        ) : null}
      </div>
      <div className="cardsContainer" >
        <NavLink className="contentCard" onClick={handleClick}>
          {currentCard.img ? (
            <div className="cardServicio">
              <img src={currentCard.img} alt={currentCard.title} loading="lazy" style={{"width":"100%"}} />
              <p className="tituloh2">{currentCard.title}</p>
              <p className="tituloh3">{currentCard.subTitle}</p>
              <p className="texto">{currentCard.text}</p>
              <p className="comentario">{currentCard.comment}</p>
            </div>
          ) : (
            <p>No hay tarjetas disponibles</p>
          )}
        </NavLink>
      </div>
      <div className="divArrow">
        {showButtons ? (
          <button className="rightArrow arrow" onClick={next}>
            <IoIosArrowForward />
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default Carousel;
