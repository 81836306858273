import Modal from '@mui/material/Modal';
import "../components/styles/successModal.css";
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  <path d="M18 6 6 18" />
  <path d="M6 6l12 12" />
</svg>

const CloseIcon = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    onClick={onClick}
  >
    <path d="M18 6 6 18" />
    <path d="M6 6l12 12" />
  </svg>
);

const SuccessModal = ({ showModal, setShowModal, sending}) => {
  const handleClick = () => {
    window.location.href = '/#section0';
  };

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      className="modal"
    >
      <div className="paper">  
        {sending ? (
          <>
          <div id="loading">
            <p className='modal-description'>Enviando...</p>
            <CircularProgress class="imgLoading" />
          </div>
          </>
          ) : (
            <>
              <h2 id="simple-modal-title">Correo</h2>
              <CloseIcon className="closeButton " onClick={() => setShowModal(false)} />            
              <div id="successDescription">    
                <p id="primary-modal-description" className='modal-description'>¡ENVIADO CON EXITO!</p>
                <p id="secondary-modal-description" className='modal-description'>Te responderemos al mail ingresado a la brevedad!</p>
                <img src="/resources/success.png" alt="success" id="successImage" />
                <Link id="continueButton" onClick={handleClick}>Continuar</Link> 
              </div>            
            </> 
          )
        }        
      </div>  
    </Modal>
  );
};

export default SuccessModal;