import { Routes, Route } from 'react-router';
import Home from './pages/home.js';
import ClasesDeApoyo from './pages/servicios/clasesApoyo.js';
import Entregas from './pages/servicios/entregas.js';
import Cursos from './pages/servicios/cursosOnline.js';
import Resumenes from './pages/servicios/resumenes.js';
import "./components/home/section4/scss/main.scss"
import "./App.css";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />          
      <Route path="/servicios/clases" element={<ClasesDeApoyo/>} />
      <Route path="/servicios/entregas" element={<Entregas/>} />
      <Route path="/servicios/cursos" element={<Cursos/>} />
      <Route path="/servicios/resumenes" element={<Resumenes/>} />
    </Routes>
  );
};

export default App;