import React from 'react'
import '../../App.css';
import Section9 from "../../components/home/section9/seccion9";
import Proximamente from "../../components/home/section9/proximamente";
import NavBar from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";

const Resumenes = () => {
  return (
    <div className="contenedorIndex">
      <NavBar /> 
      <section id="sectionHeader" className="section" style={{height:55}}/>      
      <Proximamente h1="Resumenes" h2="PROXIMAMENTE!" texto="Estamos desarrollando resumenes para toda la comunidad de Aprendemos de TODO, seguinos en las redes y youtube para no perderte de nada!"/>   
      <Section9 />
      <Footer />
    </div>
  )
}

export default Resumenes