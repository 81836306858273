import React from 'react'
import FormRegister from '../formRegister'
import '../../styles/sectionFormulario.css'
import { useState, useEffect } from 'react';
import SuccessModal from '../../successModal';

const SectionFormulario = (props) => {
  const [currentImage, setCurrentImage] = useState(0);  
  const images = ['/resources/section5/a.webp', '/resources/section5/b.webp'];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 7000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <section id="section5" className='section'> 
    
      <div id="divMenuSection5">
        <div id="contact-container">
          <p className='tituloh2' id="contact-title">{props.titulo}</p>
            <FormRegister tipoForm={"claseDeApoyo"} />            
          </div>          
      </div>     

      <div id="contact-info" >
        {images[currentImage] === '/resources/section5/a.webp' ? (
          <img id="contact-info-image" src="/resources/section5/a.webp" />
        ) : (
          <img id="contact-info-image" src="/resources/section5/b.webp" />
        )}        
      </div> 
        
    </section>
  )
}

export default SectionFormulario
