import React from 'react'
import '../styles/navBar.css';
import { Link } from 'react-router-dom';

const Logo = ({ scrollUp, mediaMin }) => {
    const handleClick = () => {
        window.location.href = '/#section0';
    };

    return (
        <div id="logoHeader" className={(scrollUp || !mediaMin) ? 'scrolled' : ''}>
            <div id="logo" className={(scrollUp || !mediaMin) ? 'logo scrolled' : 'logo'}>
                <Link id="logoLink" onClick={handleClick}>
                    <img src="/resources/logo.jpeg" alt="Logo" />
                </Link>                
            </div>
            <h2 id='titulo' onClick={handleClick}>Aprendemos de TODO</h2>
        </div>
    );
};

export default Logo;
