import React from 'react'
import img1 from "../assets/section2/1.png";
import '../styles/section2.css';

const Contenedor = (props) => {
    return (
        <div id="divContenedorSection2">
            <p className='tituloh1'>{props.h1}</p>
            <div id='contenedorSection2'>
                <img src={img1} />
                <div id="divTextSection2">
                    <p className='tituloh2'>{props.h2}</p>
                    <p className="texto">{props.texto}</p>
                </div>
            </div>
        </div>
    )
}


const Section2 = (props) => {
  return (
    <section id="section2" className='section'> 
        <div id="divMenuSection2">
            <Contenedor {...props}/>
        </div>
    </section>
  )
}

export default Section2
