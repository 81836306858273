import React from 'react'
import { NavLink } from 'react-router-dom';
import '../styles/navBar.css';
import  materias from './servicios';
import { Link } from 'react-router-dom';

const NavMenu = ({ setMenuOpen })  => {
    const handleClick = () => {
        window.location.href = '/#section0';
        setMenuOpen(false);
    };

    const handleClick2 = () => {
      window.location.href = '/#section6';
      setMenuOpen(false);
  };

    return (
        <div id="navMenu">
            <nav id="navLinksMenuLateral" className=''>
                <ul>
                <li>
                    <Link id="contacto" to="/servicios/clases/" className="navlinksdiv contacto">
                    <span>Quiero una clase!</span>
                    </Link>
                </li>
                <br/>
                <div className="separatorMenu"></div>
                <br/>
                <li><Link id="inicio" onClick={handleClick} className="navlinksmenu">Inicio</Link></li>
                <li><Link id="nosotros" onClick={handleClick2} className="navlinksmenu">Nosotros</Link></li>           
                <br/>
                <div className="separatorMenu"></div>
                <br/>
                {materias.map((item) => (
                    <li key={item.id}>
                        <NavLink to={item.link} className="navlinksmenu">
                            {item.title}
                        </NavLink>
                    </li>
                ))}
                <br/>
                <div className="separatorMenu"></div>
                <br/>
                </ul>
            </nav>
        </div>
    )
}

export default NavMenu;
