import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Menu } from 'antd';
import '../styles/navBar.css';
import { NavLink } from 'react-router-dom';
import materias from './servicios';

const Explorar = ({ scrolled }) => {
  const [mediaMin, setMediaMin] = useState(
    window.matchMedia("(min-width: 1300px)").matches
  );
  const [numItems, setNumItems] = useState(6);

  useEffect(() => {
    // Media Query
    const mediaQuery = window.matchMedia("(min-width: 1300px)");
    const handleMediaQueryChange = (e) => {
      setMediaMin(e.matches);
    };
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    setMediaMin(mediaQuery.matches);

    // Contar el número de elementos en el menú
    const menu = document.getElementById('dropdown-menu');
    if (menu) {
      setNumItems(menu.children.length);
    }
  }, []);

  // Calcular el ancho del botón en función del número de elementos en el menú
  const botonWidth = 120 + (numItems - 6) * 20;

  const menuItems = materias.map((item) => ({
    key: item.id,
    label: (
      <NavLink to={item.link} className="navlinksdropmenu">
        {item.title}
      </NavLink>
    ),
  }));

  const menu = <Menu items={menuItems} id="dropdown-menu" />;

  return (
    <div id="explorarHeader" style={{ width: `${botonWidth}px` }} className={(!mediaMin) ? 'scrolled' : ''}>
      <Dropdown overlay={menu} trigger={['click']}>
        <a onClick={(e) => e.preventDefault()} className="ant-dropdown-link">
          <Space>
            Explorar
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default Explorar;
