import React from 'react'
import img1 from "../assets/section3/1.png";
import '../styles/section3.css';


const Contenedor = (props) => {
    return (
        <div id="divContenedorSection3">
            <p className='tituloh1'>{props.h1}</p>
            <div id='contenedorSection3'>                
                <div id="divTextSection3">
                    <p className='tituloh2'>{props.h2}</p>
                    <p className="texto">{props.texto}</p>
                </div>
                <img src={img1} />
            </div>
        </div>
    )
}

const Section3 = (props) => {
  return (
    <section id="section3" className='section'> 
        <div id="divMenuSection3">
            <Contenedor {...props} />
        </div>
    </section>
  )
}

export default Section3
