import { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import '../styles/navBar.css';
import NavDiv from './navDiv';
import Logo from './logo';
import Explorar from './explorar';
import MenuLateral from './menuLateral';

const Contenedor = ({ children, scrollUp, mediaMin }) => {
  return (
      <section id="contenedor-header" className={(scrollUp || !mediaMin) ? 'contenedor-header scrolled' : 'contenedor-header'} >
          {children}
      </section>
  );
}

const NavBar = () => {
    const [scrollUp, setScrollUp] = useState(false);
    const [mediaMin, setMediaMin] = useState(
      window.matchMedia("(min-width: 900px)").matches
    );
    
    const props = { scrollUp, mediaMin };

    useEffect(() => {
      // Media min
      const mediaQuery = window.matchMedia("(min-width: 900px)");
      const handleMediaQueryChange = (e) => {
        setMediaMin(e.matches);
      };
      mediaQuery.addEventListener('change', handleMediaQueryChange);
      setMediaMin(mediaQuery.matches);

      // NavBar scroll effect
      const handleScroll = () => {
          let ubiScroll = window.scrollY;
          if (ubiScroll >= 0 && ubiScroll <= 5) {
              setScrollUp(false);
            } else {
              setScrollUp(true);
            }
      };
      window.addEventListener('scroll', handleScroll);

    }, []);

    return (
      <Contenedor {...props}>
        <header id="header">

          <Logo {...props}/>      
          {mediaMin ? <Explorar {...props}/>: <span/>}
          {mediaMin ? <NavDiv  {...props}/>: <MenuLateral {...props}/>}

        </header>
      </Contenedor>
    );
};  

export default NavBar;  