import React from 'react'
import '../styles/navBar.css';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

const NavDiv = ({ scrollUp, mediaMin }) => {
    const handleClick = () => {
        window.location.href = '/#section0';
    };

    const handleClick2 = () => {
      window.location.href = '/#section6';
  };
  
    return (      
      <div id="navDiv" className={(scrollUp || !mediaMin) ? 'scrolled' : ''}>
        <nav id="navLinksDiv" className=''>
            <ul>
              <li><Link id="inicio" onClick={handleClick} className="navlinksdiv">Inicio</Link></li>
              <li><Link id="nosotros" onClick={handleClick2} className="navlinksdiv">Nosotros</Link></li>
              <li>
                <Link id="contacto" to="/servicios/clases/" className="navlinksdiv contacto">
                  <span>Quiero una clase!</span>
                </Link>
              </li>
            </ul>
          </nav>
      </div>
    )
  }
export default NavDiv;