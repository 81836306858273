import { useField } from "formik";
import TextField from '@mui/material/TextField';
import '../styles/sectionFormulario.css'

const TextArea = ({ ...props }) => {
    const [field, meta] = useField(props);

    return (
        <TextField className="contact-comment" {...field} {...props}/>
    );
}

export default TextArea;