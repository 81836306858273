import React from 'react'
import '../../App.css';
import SectionFormulario from "../../components/formRegister/sectionFormulario/sectionFormulario";
import Section2 from "../../components/informacion/section2";
import Section3 from "../../components/informacion/section3";
import NavBar from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";

const ClasesApoyo = () => {
  return (
    <div className="contenedorIndex">
      <NavBar /> 
      <section id="sectionHeader" className="section" style={{height:55}}/>      
      <Section2 
        h1={"¿Cómo se dictan las clases?"}
        h2={"Reuniones particulares online, personalizadas y en directo"} 
        texto={"Accede desde tu ordenador o portátil a una plataforma de videoconferencia (Google Meet): Junto con las herramientas para que tengas la mejor experiencia online con video y chat, pizarra digital, editor de documentos colaborativo..."}/>
      <Section3 
        h1={"¿Cuándo?"}
        h2={"Cuando tú lo decidas!"}
        texto={"En nuestro compromiso con vos, acordaremos juntos un horario que te resulte conveniente, priorizando tus tiempos y necesidades, especialmente si estás por rendir. Nuestros horarios buscan brindarte la flexibilidad que necesitas."}
        />
      <SectionFormulario 
        titulo={"Reserva tu clase!"} />
      <Footer />
    </div>
  )
}

export default ClasesApoyo

